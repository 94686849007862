import React from "react"
import { Link } from "gatsby"
import {
  MarkdownRemark,
  Maybe,
  MarkdownRemarkFields,
  MarkdownRemarkFrontmatter,
} from "../generated/graphql"
import idx from "idx"

const blogPath = "/in-the-studio"

const Feed = ({
  edges,
}: {
  edges: Array<{
    node: Pick<MarkdownRemark, "excerpt" | "html"> & {
      fields?: Maybe<Pick<MarkdownRemarkFields, "slug">>
      frontmatter?: Maybe<
        Pick<MarkdownRemarkFrontmatter, "title"> & {
          slugDate: MarkdownRemarkFrontmatter["date"]
          displayDate: MarkdownRemarkFrontmatter["date"]
          niceDate: MarkdownRemarkFrontmatter["date"]
        }
      >
    }
  }>
}) => {
  return (
    <div>
      {edges.map((edge) => {
        const slug = idx(edge, (_) => _.node.fields.slug) || ""
        const slugDate = idx(edge, (_) => _.node.frontmatter.slugDate) || ""
        const displayDate =
          idx(edge, (_) => _.node.frontmatter.displayDate) || ""
        const niceDate = idx(edge, (_) => _.node.frontmatter.niceDate) || ""
        const title = idx(edge, (_) => _.node.frontmatter.title)
        const html = idx(edge, (_) => _.node.html) || ""
        const path = `${blogPath}/${slugDate}/${slug}`

        return (
          <div key={slug}>
            <h1>
              <Link to={path}>{title}</Link>
            </h1>
            <h3 className="text-center">
              - <time dateTime={niceDate}>{displayDate}</time> -
            </h3>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        )
      })}
    </div>
  )
}

export default Feed
