import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { PostIndexPageQuery } from "../generated/graphql"
import Feed from "../components/feed"
import Pagination from "../components/pagination"

const IndexTemplate = ({
  data,
  pageContext,
}: {
  data: PostIndexPageQuery
  pageContext: {
    currentPage: number
    postsLimit: number
    postsOffset: number
    prevPagePath: string
    nextPagePath: string
    hasPrevPage: boolean
    hasNextPage: boolean
  }
}) => {
  const { hasNextPage, hasPrevPage, prevPagePath, nextPagePath } = pageContext

  const { edges } = data.allMarkdownRemark

  return (
    <Fragment>
      <Feed edges={edges} />
      <Pagination
        prevPagePath={prevPagePath}
        nextPagePath={nextPagePath}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
      />
    </Fragment>
  )
}

export const query = graphql`
  query PostIndexPage($postsLimit: Int!, $postsOffset: Int!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allMarkdownRemark(
      limit: $postsLimit
      skip: $postsOffset
      filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          html
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            slugDate: date(formatString: "YYYY-MM-DD")
            displayDate: date(formatString: "MMMM YYYY")
            niceDate: date(formatString: "MMMM D, YYYY")
          }
        }
      }
    }
  }
`

export default IndexTemplate
