import React from "react"
import { Link } from "gatsby"

const Pagination = ({
  prevPagePath,
  nextPagePath,
  hasNextPage,
  hasPrevPage,
}: {
  prevPagePath: string
  nextPagePath: string
  hasNextPage: boolean
  hasPrevPage: boolean
}) => {
  return (
    <div>
      {hasPrevPage && (
        <div>
          <Link rel="prev" to={prevPagePath}>
            Prev
          </Link>
        </div>
      )}
      {hasNextPage && (
        <div>
          <Link rel="next" to={nextPagePath}>
            Next
          </Link>
        </div>
      )}
    </div>
  )
}

export default Pagination
